<template>
  <div class="wa--start">
    <validation-observer v-if="!loggedIn" v-slot="{ handleSubmit }">
      <cv-form @submit.prevent="">
        <h3 class="wind--title">
          管理员登录
        </h3>
        <validation-provider
          name="邮箱"
          rules="required"
          v-slot="{ errors }">
          <cv-text-input
            label="邮箱"
            v-model="loginData.email">
            <template v-if="errors[0]" v-slot:invalid-message>
              {{ errors[0] }}
            </template>
          </cv-text-input>
        </validation-provider>
        <validation-provider
          name="密码"
          rules="required"
          v-slot="{ errors }">
          <cv-text-input
            label="密码"
            v-model="loginData.password"
            ref="passwordInput">
            <template v-if="errors[0]" v-slot:invalid-message>
              {{ errors[0] }}
            </template>
          </cv-text-input>
        </validation-provider>
        <cv-button
          kind="primary"
          @click="handleSubmit(login)">
          登录
        </cv-button>
      </cv-form>
    </validation-observer>
    <div style="text-align: center;" v-if="loggedIn">
      <h3 class="wind--title">
        已登录
      </h3>
      <cv-button
        kind="primary"
        @click="$router.push('/dashboard')">
        前往操控面板
      </cv-button>
    </div>
  </div>
</template>
<script>
import {
  ValidationProvider,
  ValidationObserver
} from 'vee-validate'
import {
  CvForm,
  CvTextInput,
  CvButton
} from '@carbon/vue/src/index'
export default {
  name: 'Start',
  components: {
    ValidationProvider,
    ValidationObserver,
    CvForm,
    CvTextInput,
    CvButton
  },
  data: () => ({
    loginData: {
      email: null,
      password: null
    }
  }),
  computed: {
    loggedIn (){
      return this.$store.getters['user/auth/logged-in']
    }
  },
  mounted (){
    if (!this.loggedIn) {
      this.hackPasswordInput()
    }
  },
  methods: {
    login (){
      this.$api.user.getAdminPassport(this.loginData).then( r => {
        if (r.ok) {
          this.$store.dispatch('user/auth/login', r.data.jwt).then( () => {
            this.$store.dispatch('ui/toast-notification/show', {
              title: '登陆成功',
              kind: 'success'
            })
            this.$router.push('/dashboard')
          })
        } else {
          this.$store.dispatch('ui/toast-notification/show', {
            title: '登录出错',
            kind: 'error',
            caption: r.error.description
          })
        }
      })
    },
    hackPasswordInput (){
      this.$refs.passwordInput.togglePasswordVisibility()
      this.$refs.passwordInput.togglePasswordVisibility()
    }
  }
}
</script>